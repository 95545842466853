import ContactUs from "./ContactUs";
import Footer from "./Footer";
import NoticeComponent from "./NoticeComponent";
function ViewNotice() {
  return (
    <>
    <NoticeComponent/>
      <ContactUs />
      <Footer />
    </>
  );
}

export default ViewNotice;
