import React, { useEffect, useState } from 'react';
import MainCard from '../../ui-component/cards/MainCard';
import HomePageService from "../../services/HomePage";
import GPSHistoryMap from "./HistoryPlaybackMap";
import { FormControl, InputLabel, Select, MenuItem, TextField, Button, Grid } from '@mui/material';

const HistoryPlayback = () => {
  const currentDate = new Date().toISOString().split('T')[0];
  const yesterday = new Date(Date.now() - 86400000).toISOString().split('T')[0];
  const [load, setLoad] = useState(false);
  const [vehicleNo, setVehicleNo] = useState('GEM1205-04-00');
  const [fromDate, setFromDate] = useState("2024-09-14T12:00:00");//useState(yesterday);
  const [toDate, setToDate] = useState("2024-09-14T17:59:59");//useState(currentDate);
  const [vehicleList, setVehicleList] = useState([]);
  const [downloadStatus, setDownloadStatus] = useState("");
  // const [fromDate, setStartDateTime] = 
  // const [toDate, setEndDateTime] = 
  // const [vehicleNo, setVehicleRegistrationNumber] = useState("");
  const [showMap, setShowMap] = useState(false); // To control visibility of the map

  useEffect(() => {
    const fetchVehicleList = async () => {
      const retriveData = await HomePageService.getVehicleList();
      setVehicleList(retriveData.data);
      setLoad(true);
    };
    fetchVehicleList();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Set the state values based on form inputs
    //setStartDateTime(fromDate);
    //setEndDateTime(toDate);
    //setVehicleRegistrationNumber(vehicleNo);

    // Display the map after the form is submitted
    setShowMap(true);
    console.log("Submitted data:", { vehicleNo, fromDate, toDate });
  };

  const handleVehicleNoChange = (e) => {
    setVehicleNo(e.target.value);
  };

  const handleFromDateChange = (e) => {
    setFromDate(e.target.value);
  };

  const handleToDateChange = (e) => {
    setToDate(e.target.value);
  };

  return (
    <MainCard>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2} className="form-controller">
          <Grid item md={4} sm={12} xs={12} style={{ marginTop: "20px" }}>
            <FormControl fullWidth>
              <InputLabel>Vehicle No</InputLabel>
              <Select value={vehicleNo} onChange={handleVehicleNoChange}>
                <MenuItem value="">Select</MenuItem>
                {vehicleList.length > 0 ? (
                  vehicleList.map((item) => (
                    <MenuItem value={item} key={item}>
                      {item}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="">Wait Fetching Vehicle List</MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>

          <Grid item md={3} sm={12} xs={12} style={{ marginTop: "20px" }}>
            <TextField
              fullWidth
              label="From Date"
              type="datetime-local"
              value={fromDate}
              onChange={handleFromDateChange}
              inputProps={{ max: yesterday }}
            />
          </Grid>

          <Grid item md={3} sm={12} xs={12} style={{ marginTop: "20px" }}>
            <TextField
              fullWidth
              label="To Date"
              type="datetime-local"
              value={toDate}
              onChange={handleToDateChange}
              InputLabelProps={{ shrink: true }}
              inputProps={{ max: currentDate }}
            />
          </Grid>

          <Grid item md={2} sm={12} xs={12} style={{ marginTop: "20px" }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{ height: "48px" }}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>

      {/* Show the map only after the form is submitted */}
      <div style={{ paddingTop: "20px" }}>
        {showMap && (
          <GPSHistoryMap
            startDateTime={fromDate}
            endDateTime={toDate}
            vehicleRegistrationNumber={vehicleNo}
            downloadStatus={downloadStatus}
            setDownloadStatus={setDownloadStatus}
          />
        )}
      </div>
    </MainCard>
  );
};

export default HistoryPlayback;
