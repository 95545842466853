// theme constant
export const gridSpacing = 3;
export const drawerWidth = 260;
export const appDrawerWidth = 320;
export const SET_USER = 'SET_USER';
export const SET_LOADING = 'SET_LOADING';
export const SET_ERROR = 'SET_ERROR';
export const VERIFY_OTP='VERIFY_OTP';
export const FILE_SIZE = 512 * 1024 ; // 512 KB
export const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png", "application/pdf"];
export const BASE_URL='https://skytrack.tech:2000/'
