export const iconStyle={
    backgroundColor: "#2196f321",
    fontSize: "40px",
    fontWeight: "bold",
    padding: "4px",
    borderRadius: "4px",
    border: "2px solid white"
}
export const paperStyle={
    p: 2,
    backdropFilter: "blur(5px)",
    backgroundColor: "rgba(255, 255, 255, 0.3)",
    borderRadius: "8px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
}
export const logoStyle={
    color: "#430A5D",
    fontfamily: "Quantico",
    fontWeight: "900px",
    fontSize: "15px",
    textshadow: "2px 2px 4px",
}
export const captchaStyle = {
  textAlign: "center",
  display: "flex",
  justifyContent: "space-around",
  borderRadius: "10px",
  backgroundColor: "#cdcdcd",
};
export const replyStyle = {
  padding: "4px 4px 1px 4px",
  cursor: "pointer",
  paddingTop: "12px",
};