import { getAxiosInstance } from './axiosInstance';
const getLiveTracking = (data) => {
  const http = getAxiosInstance();
  return http.get("/api/gps-data-map/", {
    params: {
      imei: data.imei,
      regno: data.regno
    }
  });
};
const getLiveTracking_data = (data) => {
  const http = getAxiosInstance();
  return http.get("/api/gps_track_data_api", {
    params: {
      imei: data.imei,
      regno: data.regno
    }
  });
};



const getSOSDataAdmin = () => {
  const http = getAxiosInstance();
  return http.get("/api/emergency-call-listener-admin/");
};
const getSOSDataTeamLead = () => {
  const http = getAxiosInstance();
  return http.get("/api/emergency-call-listener-team-lead/");
}
const getSOSDataExe = () => {
  const http = getAxiosInstance();
  return http.get("/api/emergency-call-listener-deskexecutive/");
}

const getHistoryPlayback = (data) => {
  const http = getAxiosInstance();
  return http.get("/api/gps_history_map/", {
    params: {
      start_datetime: data.fromDate,
      end_datetime: data.toDate,
      vehicle_registration_number: data.vehicleNo,
    }
  });
}
const getVehicleList = (data) => {
  const http = getAxiosInstance();
  return http.post("/api/get_live_vehicle_no/", data);
}
const getRouteFixing = (device_id) => {
  const http = getAxiosInstance();
  const data = { "device_id": device_id }
  return http.post("/api/getRoute/", data);

}
const getAllSOSCall = () => {
  const http = getAxiosInstance();
  return http.get("/api/get-all-call/");
}
const getCallDetails = (id) => {
  const http = getAxiosInstance();
  return http.get(`/api/emergency-call-details/${id}/`)
}
const broadCastHelp = (data) => {
  const http = getAxiosInstance();
  return http.post("/api/broadcast-help/", data);
}
const updateSOSCall = (data) => {
  const http = getAxiosInstance();
  return http.post("/api/submit_status/", data);
}

const addRoute = (data) => {
  const http = getAxiosInstance();
  return http.post("/api/saveRoute/", data);
}
const delRoute = (data) => {
  const http = getAxiosInstance();
  return http.post("/api/delRoute/", data);
}
const HomePageService = {
  getLiveTracking,
  getLiveTracking_data,
  getSOSDataAdmin,
  getHistoryPlayback,
  getSOSDataTeamLead,
  getSOSDataExe,
  getVehicleList,
  getRouteFixing,
  getAllSOSCall,
  getCallDetails,
  broadCastHelp,
  updateSOSCall,
  addRoute,
  delRoute

};

export default HomePageService;
