import { Container, Typography,Box } from "@mui/material";
import skytrack from "../../assets/images/skytrack.jpg";
import DetailSection from "./DetailSection";
import AboutUs from "./AboutUs";
import ContactUs from "./ContactUs";
import Footer from "./Footer";
function LandingPage() { 
  return (
    <>
      <Box
        sx={{
          position: "relative",
          height: "100vh",
          backgroundImage: `linear-gradient(to top, #9700ff5e, #fff0), url(${skytrack})`, // Replace with your image URL
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundAttachment: "fixed", // This is what creates the parallax effect
          color: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Container>
          <Typography
            variant="h2"
            component="h1"
            gutterBottom
            sx={{
              color: "white",
              fontWeight: "400",
              letterSpacing: { xs: "4px", sm: "12px", md: "24px" }, // Adjust letter spacing based on screen size
              fontSize: { xs: "24px", sm: "36px", md: "48px" }, // Responsive font size
              wordWrap: "break-word", // Ensures text breaks properly on smaller screens
            }}
          >
            WELCOME TO SKYTRACK
          </Typography>
        </Container>
      </Box>
      <DetailSection />
      <AboutUs />
      <ContactUs />
      <Footer />
    </>
  );
}

export default LandingPage;
